import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import MiniLawnChair from '../../images/miniLawnChair.svg'

const ShrubPruning = () => {
	const TheTitle = "Shrub Pruning Services"
	const TheDescription = "Pruning and trimming plants in the right way can help them flower more, grow stronger and toward your desired shape. Without the right tools and experience hedge, shrub and small tree trimming can be a daunting and messy task we have both and can quickly and safely reshape your larger plants to be tidy and easier to maintain."
	const TheCanonical = "https://gardenescape.ca/services/shrub-pruning/"

	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
			<div className="serviceGrid">
				<StaticImage
					className="serviceGrid_boxA"
					src="../../images/home/bush.png"
					alt="background placeholder"
					layout="fullWidth" />
				<div className="serviceGrid_boxB">
					<h1>{TheTitle}</h1>
					<p className="serviceGrid_boxB_text">25% off your first mow</p>
					<img
						src={MiniLawnChair}
						width="75px"
						height="75px"
						alt="garden escape mini logo" />
				</div>
			</div>
			<div className="mainWrapper my-16">
			<h2>FEELING A LITTLE SHAGGY?</h2>
			<p>Pruning and trimming plants in the right way can help them flower more, grow stronger and toward your desired shape. Without the right tools and experience hedge, shrub and small tree trimming can be a daunting and messy task we have both and can quickly and safely reshape your larger plants to be tidy and easier to maintain.</p>
			<h2>HOW DO I PROGRESS?</h2>
			<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan that’ll best fit your yard goals and budget. Just contact us through our website, phone or email.</p>
			<h2>OTHER WAYS WE CAN SERVE YOU.</h2>
			<p>We are happy to help you with all things related to beautifying your property, some other major services we offer are; Regular lawn mow, fertilizing, Seasonal Yard cleanup, Aeration and Power Raking, Power washing and Junk Removal.</p>
			</div>
		</Layout>
	)
}

export default ShrubPruning